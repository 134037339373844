import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import Navbar from '../../COMPONENTS/Navbar/Navbar'
import Search_Product_Sidebar from '../../COMPONENTS/Product/Search_Product_Sidebar'

const SearchPage = () => {
    const { searchvalue} = useParams()
    const [products, setProducts] = React.useState([])
    const [productsfiltered, setProductsfiltered] = React.useState([])
    const [categories, setCategories] = React.useState([])



    const getProducts = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/Product/GetAllWithImage?OrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                //  filter by search value

                const filtered = data.Result.filter((product) => { 
                    // console.log('product ',product)
                    return product.Name.toLowerCase().includes(searchvalue.toLowerCase())
                })
             
                setProducts(filtered)
            })
    }

    const getCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Category/GetAllWithSubcategory?OrganizationId=3`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const categoriesData = await response.json();
            let alldata = [];
            // console.log('cartegpriesdata ',categoriesData.Data)

            for (const category of categoriesData.Data) {

                let obj = {
                    category: category,
                    subcategories: category.SubCategoryDetail
                };

                alldata.push(obj);
            }

            setCategories(alldata);
        } catch (error) {
            console.log('Error:', error);
        }
    };


    useEffect(() => {
        getProducts()
        getCategories()
    }, [searchvalue])


    return (
        <div>
            <Navbar />
            <div style={{
                height: '20px',
            }}></div>
            <Search_Product_Sidebar products={products?products:[]} categories={categories} />
        </div>
    )
}

export default SearchPage