import React from 'react'
import { useRecoilState } from 'recoil'
import { newAddressProvider } from '../../Providers/NewAddressProvider'
import './AddNewAddress.css'
import logo from '../../ASSETS/logo.png'
import { toast, ToastContainer } from 'react-toastify'

const AddNewAddress = ({ user, getaddress }) => {
  const [shownewaddressform, setShownewaddressform] = useRecoilState(newAddressProvider)


  const [newaddress, setnewaddress] = React.useState({
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    IsDefault: true,
  })
  const [postalcode, setpostalcode] = React.useState('')


  const addnewaddress = () => {
    var checkbox = document.getElementById("defaultcheck");

    // console.log(checkbox.checked)
    // if (checkbox.checked === true) {
    //   let temp1 =
    //   {
    //     "OrgId": 3,
    //     "B2CCustomerId": user.B2CCustomerId,
    //     "AddressLine1": newaddress.AddressLine1,
    //     "AddressLine2": newaddress.AddressLine2,
    //     "AddressLine3": newaddress.AddressLine3,
    //     "CountryId": "INR",
    //     "PostalCode": postalcode,
    //     "ChangedBy": "admin",
    //     "ChangedOn": new Date(),
    //   }

    //   console.log(temp1)


      // fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/EditProfile', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(temp1)
      // })
      //   .then(res => res.json())
      //   .then(data => {
      //     console.log('Default Address Updated ' + data)

      //     if (data.Status === true && data.Code === 200) {
      //       console.log('Default Address Updated ' + data.Data)
      //     }
      //     else {
      //       toast.error('Error in Updating Default Address')
      //     }
      //   })
    // }

    let temp =
    {
      "OrgId": 3,
      "DeliveryId": 0,
      "CustomerId": user.B2CCustomerId,
      "Name": user.B2CCustomerName,
      "AddressLine1": newaddress.AddressLine1,
      "AddressLine2": newaddress.AddressLine2,
      "AddressLine3": newaddress.AddressLine3,
      "CountryId": "string",
      "PostalCode": postalcode,
      "Mobile": user.MobileNo,
      "Phone": "string",
      "Fax": "string",
      "IsDefault": checkbox.checked,
      "IsActive": true,
      "CreatedBy": "string",
      "CreatedOn": new Date(),
      "ChangedBy": "string",
      "ChangedOn": new Date(),
    }

    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerDeliveryAddress/Create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(temp)
    })
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.Status === true && data.Code === 200) {
          toast.success('Address Added')
          updateuserdata()
          // after 2 seconds, set the state to false
          setTimeout(() => {
            setShownewaddressform(false)
          }, 2000)

        }
        else {
          toast.error('Error Adding Address')
        }
      })

    // console.log(temp)

  }


  // const updateuserdata = () => {
  //   let user = localStorage.getItem('token')
  //   user = JSON.parse(user)
  //   // console.log('user customer id',user[0])
  //   fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/GetbycodeOrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION+'&B2CCustomerId=' + user[0].B2CCustomerId, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data)
  //       if (data.Status === true && data.Code === 200) {
  //         localStorage.setItem('token', JSON.stringify(data.Data))
  //         // setShownewaddressform(false)
  //         window.location.reload()
  //       }
  //       else {
  //         toast.error('Error Fetching User Data')
  //       }
  //     })
  // }
  const updateuserdata = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    // console.log('user customer id',user[0])
    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/Getbycode?OrganizationId=' + process.env.REACT_APP_BACKEND_ORGANIZATION + '&B2CCustomerId=' + user[0].B2CCustomerId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(data => {
        console.log(data);
        if (data.Status === true && data.Code === 200) {
          localStorage.setItem('token', JSON.stringify(data.Data));
          window.location.reload();
        } else {
          toast.error('Error Fetching User Data');
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        toast.error('Failed to fetch user data');
      });
  
  }



  function toggleCheckbox() {
    var checkbox = document.getElementById("defaultcheck");
    checkbox.checked = !checkbox.checked;

    setnewaddress({
      ...newaddress,
      IsDefault: checkbox.checked
    })

    console.log(newaddress)
  }


  return (
    <div
      className='add-new-address-out'
    >
      <ToastContainer theme='dark' />
      <div
        className='add-new-address-in'
      >
        <button className='auth-popup__close-btn'
          onClick={() => {
            setShownewaddressform(false)
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>


        <form>
          <h1>Add New Address</h1>
          <div className='formcont'>
            <label htmlFor='postalcode'>Postal Code</label>
            <div>
              <input type='text' name='postalcode' id='postalcode'
                value={postalcode}
                onChange={(e) => {
                  setpostalcode(e.target.value)
                }}
              />
              <button className='btn'
                onClick={async (e) => {
                  e.preventDefault()
                  let url = `https://developers.onemap.sg/commonapi/search?searchVal=${postalcode}&returnGeom=N&getAddrDetails=Y&pageNum=1`
                  const response = await fetch(url);
                  const data = await response.json();
                  console.log(data.results[0])

                  setnewaddress({
                    ...newaddress,
                    AddressLine3: data.results[0].ADDRESS,
                  })

                }}

              >Fetch</button>
            </div>
          </div>
          <div className='formcont'>
            <label htmlFor='addressline1'>Address Line 1</label>
            <input type='text' name='addressline1' id='addressline1'
              value={newaddress.AddressLine1}
              onChange={(e) => {
                e.preventDefault()
                setnewaddress({ ...newaddress, AddressLine1: e.target.value })
              }}
            />
          </div>
          <div className='formcont'>
            <label htmlFor='addressline2'>Address Line 2</label>
            <input type='text' name='addressline2' id='addressline2'
              value={newaddress.AddressLine2}
              onChange={(e) => {
                e.preventDefault()
                setnewaddress({ ...newaddress, AddressLine2: e.target.value })
              }}
            />
          </div>

          <div className='formcont'>
            <label htmlFor='addressline3'>Address Line 3</label>
            <input type='text' name='addressline3' id='addressline3'
              value={newaddress.AddressLine3}
              onChange={(e) => {
                e.preventDefault()
                setnewaddress({ ...newaddress, AddressLine3: e.target.value })
              }}
            />
          </div>
          <div className='formcont1'>
            <label>Set as Default</label>
            <input type='checkbox' name='default' id='defaultcheck'
              //  check uncheck on single click
            />
          </div>


          <button className='btn'
            onClick={(e) => {
              e.preventDefault()
              addnewaddress()
            }}
          >Save Address</button>
        </form>
        <br />
        <br />
        <br />
        <br />
        <br />

      </div>
    </div>
  )
}

export default AddNewAddress