import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer2 from '../../COMPONENTS/Footer/Footer2'
import Navbar from '../../COMPONENTS/Navbar/Navbar'
import './Sucess.css';


const Sucess = () => {
  const [ordersuccessorderid, setordersuccessorderid] = React.useState(null)
  const preorderarray = JSON.parse(localStorage.getItem('preorderarray'));
  console.log(preorderarray);
  const placeorder = () => {
  fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerOrder/Create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(preorderarray)
  })
    .then(res => res.json())
    .then(data => {
      console.log('Order Code ', data.Data)
      if (data.Message == 'Sucess' && data.Code == 200) {
        setordersuccessorderid(data.Data)
       // localStorage.removeItem('preorderarray');
      }
      else {
       // toast.error('Order Not Placed')
      }
    })
    .catch(err => {
     // toast.error('Order Not Placed')
    })
  }

  useEffect(() => {
    placeorder();
  }, []);

    
  return (
    <div>
    <Navbar />
    <div className="success-container">
        <h1 className="success-heading">Thank You for Your Order!</h1>
        <p className="success-message">Your order has been successfully placed. </p>
        <div className="order-summary">
          {/* Display Order details here */}
        </div>
        <div className="shipping-info">
          {/* Display shipping details here */}
        </div>
        <div className="payment-info">
          {/* Display payment details here */}
        </div>
        <p className="order-number">Your Order Number: {ordersuccessorderid}</p>
        <Link to="/" className="continue-shopping-link">
          <button className="continue-shopping-btn">Continue Shopping</button>
        </Link>
      </div>
    
    </div>
  )
}

export default Sucess