import React, { useEffect, useState } from 'react'
import BannerSlider from '../../COMPONENTS/Banners/BannerSlider'
import HomeCategories from '../../COMPONENTS/Category/HomeCategories'
import Footer1 from '../../COMPONENTS/Footer/Footer1'
import Footer2 from '../../COMPONENTS/Footer/Footer2'
import Navbar from '../../COMPONENTS/Navbar/Navbar'
import Product_Sidebar from '../../COMPONENTS/Product/Product_Sidebar'
import img1 from '../../ASSETS/Images/1.png'
import img2 from '../../ASSETS/Images/2.png'
import img3 from '../../ASSETS/Images/3.png'
import img4 from '../../ASSETS/Images/4.png'
import ProductsSlider from '../../COMPONENTS/Product/ProductsSlider'
import { useParams } from 'react-router-dom'

import CategorySidebar from '../../COMPONENTS/Product/CategorySidebar'
import CategoryTopbar from '../../COMPONENTS/Product/CategoryTopbar'
import ProductCard from '../../COMPONENTS/Product/ProductCard'
import SlidingTopText from '../../COMPONENTS/SlidingTopText/SlidingTopText'
import ClipLoader from "react-spinners/ClipLoader";

const Home1 = () => {
  const { categoryid, categoryname, subcategory } = useParams()
  const [products, setProducts] = React.useState([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [categories, setCategories] = React.useState([])
  const [sortby, setSortby] = useState('lowtohigh')
  const [pagenumber, setpagenumber] = useState(1)
  const [isgetProguct, setisgetProguct] = useState(false)

  const getProducts = (number) => {
    setisgetProguct(false)
    setLoadingProducts(true)
    let url = undefined;
    if (categoryid && categoryid !== 'all') {
      if (subcategory == 'all') {
        url = process.env.REACT_APP_BACKEND_URL + `/Product/GetAllWithImage?OrganizationId=3&Category=${categoryid}&pageNo=${number}`
      } else {
        url = process.env.REACT_APP_BACKEND_URL + `/Product/GetAllWithImage?OrganizationId=3&Category=${categoryid}&SubCategory=${subcategory}&pageNo=${number}`
      }
    } else {
      url = process.env.REACT_APP_BACKEND_URL + '/Product/GetAllWithImage?OrganizationId=3&pageNo=' + number
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setLoadingProducts(false)
        console.log(data)
        if (data.PageSize > pagenumber) {
          setpagenumber((prev) => prev + 1)
          setisgetProguct(true)
        }
        setProducts([...products || [], ...data.Result || []])
      })


    // if (categoryid && categoryid !== 'all') {
    //   if(subcategory == 'all'){
    //     fetch(process.env.REACT_APP_BACKEND_URL + `/Product/GetAllWithImage?OrganizationId=3&Category=${categoryid}`, {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       }
    //     })
    //       .then(response => response.json())
    //       .then(data => {
    //         // console.log(data)
    //         setProducts(data.Result)
    //       })
    //   }
    //   else{
    //     fetch(process.env.REACT_APP_BACKEND_URL + `/Product/GetAllWithImage?OrganizationId=3&Category=${categoryid}&SubCategory=${subcategory}`, {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       }
    //     })
    //       .then(response => response.json())
    //       .then(data => {
    //         setProducts(data.Result)
    //       })
    //   }
    // }

    // else {
    //   fetch(process.env.REACT_APP_BACKEND_URL + '/Product/GetAllWithImage?OrganizationId=3&pageNo='+ number , {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     }
    //   })
    //     .then(response => response.json())
    //     .then(data => {
    //       console.log(data)
    //       if(data.PageSize > pagenumber){
    //         setpagenumber(pagenumber+1)
    //         setisgetProguct(true)
    //       }
    //       setProducts([ ...products || [],  ...data.Result || []])
    //     })
    // }
  }


  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Category/GetAllWithSubcategory?OrganizationId=3`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const categoriesData = await response.json();
      let alldata = [];
      // console.log('cartegpriesdata ', categoriesData.Data)

      for (const category of categoriesData.Data) {

        let obj = {
          category: category,
          subcategories: category.SubCategoryDetail
        };

        alldata.push(obj);
      }

      setCategories(alldata);
    } catch (error) {
      console.log('Error:', error);
    }
  };


  const checkifinwhishlist = (code) => { }


  useEffect(() => {
    getProducts()
    getCategories()
    setpagenumber(1)
    setProducts([])
  }, [categoryid])

  const [visible, setVisible] = useState(false);
  const size = 1000
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > size) {
      setVisible(true)
    }
    else if (scrolled <= size) {
      setVisible(false)
    }
    // if(scrolled > size &&   isgetProguct){
    //   getProducts(pagenumber)
    // }
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
  }, [])

  useEffect(() => {
    getProducts(pagenumber)
    // alert(pagenumber)
  }, [pagenumber])

  return (
    <div>
      <SlidingTopText />


      <Navbar />

      <BannerSlider />
      <HomeCategories />
      {/* <Product_Sidebar products={products?products:[]} categories={categories} categoryname={categoryname} data={{categoryid, categoryname,subcategory}} /> */}
      <div className='product_sidebar'>
        <CategorySidebar categories={categories} className="product_sidebar1" />
        <div className='allproducts'>
          <CategoryTopbar categories={categories} />
          <div className='header'>
            {
              products && products?.length > 0 && categoryname ?
                <h1>{categoryname}</h1>
                :
                <h1>{
                  products?.length != 1 ? `${products?.length || 0} Products Found`
                    :
                    `${products?.length || 0} Product Found For`
                }</h1>
            }
            <div className='sortby'>
              <span>Sort by : </span>
              <select

                value={sortby}
                onChange={(e) => setSortby(e.target.value)}

              >
                <option value='lowtohigh'>Low to High</option>
                <option value='hightolow'>High to Low</option>
                <option value='newestfirst'>Newest First</option>
              </select>
            </div>
          </div>
          {products && products.length > 0 ?
            <div className='products'>
              {
                products
                  .map((item) => {
                    return (
                      <ProductCard data={item} key={item.Code} wishlist={
                        checkifinwhishlist(item.Code)
                      } />
                      // <p>{JSON.stringify(item)}</p>
                    )
                  })


              }
            </div>
            :
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
                width: '100%',
              }}
            >

              <h1 >No Products Found</h1>
            </div>
          }

       {
        loadingProducts && 
        <div 
        style={{
           width: '100%',
           display:"flex",
           justifyContent:"center"
        }}
        >
          <ClipLoader color="#36d7b7" />
        </div>
       }

        </div>


        {visible ? <div className='scrollToTop' onClick={() => { window.scrollTo(0, size - 500); }} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
          </svg>
        </div  > : ""}


      </div>


      <Footer1 />

      {/* <div className='slidercont'>
                <ProductsSlider products={products} categoryname='Related Products' />
            </div>
            <div className='slidercont'>
                <ProductsSlider products={products} categoryname='Explore More' />
            </div> */}
      <Footer2 />
    </div>
  )
}

export default Home1