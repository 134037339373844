import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../../ASSETS/logo.png'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom'
import DropdownComponent from './DropdownComponent'
import AuthPopup from '../Auth/AuthPopup'
import { authPopupState } from '../../Providers/AuthPopupProvider'
import { useRecoilState } from 'recoil'
import { loginState } from '../../Providers/LoginProvider'
import Cart from '../Cart/Cart'
import { cartPopupState } from '../../Providers/CartPopupProvider'
import { cartReloadState } from '../../Providers/CartReload'
import { cartQuantity } from '../../Providers/CartQuantity'
import { toast, ToastContainer } from 'react-toastify'
import { productPopupProvider } from '../../Providers/ProductpopupProvider'
import ProductPopup from '../Product/ProductPopup'
import { searchValueProvider } from '../../Providers/SearchValueProvider'
import { wishPopupState } from '../../Providers/WishPopupProvider'
import Wishlist from '../Wishlist/Wishlist'
import { wishQuantity } from '../../Providers/WishListQuantityProvider'
const Navbar = () => {
    const [cartdataquantity, setcartdataquantity] = useRecoilState(cartQuantity)
    const [wishlistdataquantity, setwishlistdataquantity] = useRecoilState(wishQuantity)
    const [categories, setCategories] = useState([])

    const getCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Category/GetAllWithSubcategory?OrganizationId=3`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const categoriesData = await response.json();
            let alldata = [];
            // console.log('cartegpriesdata ',categoriesData.Data)

            for (const category of categoriesData.Data) {

                let obj = {
                    category: category,
                    subcategories: category.SubCategoryDetail
                };

                alldata.push(obj);
            }

            setCategories(alldata);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    useEffect(() => {
        getCategories()
    }, [])

    const dropdownitems = [
        {
            id: 1,
            title: 'Home',
            link: '/'
        },
        {
            id: 2,
            title: 'Categories',
            items:
                categories

        },
        {
            id: 3,
            title: 'About Us',
            link: '/about'
        },
        {
            id: 4,
            title: 'Contact Us',
            link: '/contact'
        },
        {
            id: 5,
            title: 'Offers',
        }
    ]

    const [authPopupShow, setAuthPopupShow] = useRecoilState(authPopupState);
    const [cartPopupShow, setCartPopupShow] = useRecoilState(cartPopupState);
    const [wishlistpopupshow, setwishlistpopupshow] = useRecoilState(wishPopupState);

    const [loggedIn, setLoggedIn] = useRecoilState(loginState);
    const [user, setuser] = useState(null)
    const [carttotal, setcarttotal] = useState(0)
    const checklogin = () => {
        // localStorage.getItem('token') ?
        //     setLoggedIn(true) :
        //     setLoggedIn(false)
        let user = localStorage.getItem('token')
        user = JSON.parse(user)
        if (user) {
            setLoggedIn(true)
            // console.log(token)

            // console.log('user customer id',user[0])
            // fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/GetbycodeOrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION+'&B2CCustomerId=' + user[0].B2CCustomerId, {
                fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/Getbycode?OrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION+'&B2CCustomerId=' + user[0].B2CCustomerId, {   
            method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data.Data[0])
                    setuser(data?.Data[0])
                    getaddress(data?.Data[0])
                    localStorage.setItem('token', JSON.stringify(data.Data))
                    user = localStorage.getItem("token")
                })

        }
        else {
            setLoggedIn(false)
        }
    }

    const [defaultaddress, setdefaultaddress] = useState(null)

    const getaddress = (userdata) => {
        // console.log(userdata)
        let mainaddress = {
            AddressLine1: userdata?.AddressLine1,
            AddressLine2: userdata?.AddressLine2,
            AddressLine3: userdata?.AddressLine3,
            EmailId: userdata.EmailId,
            default: true
        }
        let otheraddress = [];
        fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerDeliveryAddress/GetAll?OrganizationId=3&CustomerId=' + userdata.B2CCustomerId)
            .then(res => res.json())
            .then(data => {
                
                if (data.Data != null) {
                    otheraddress = data.Data
                    if (mainaddress.AddressLine1 == '' && mainaddress.AddressLine2 == '' && mainaddress.AddressLine3 == '') {
                        let alladdress = [
                            ...otheraddress
                        ]
                
                        // find IsDefault true
                        let tempdefaultaddress = alladdress.find((address) => {
                            return address.IsDefault == true
                        }    )
                        console.log(tempdefaultaddress)
                        if(tempdefaultaddress){
                            setdefaultaddress(tempdefaultaddress)
                        }
                    }

                    else {
                        let alladdress = [
                            ...otheraddress,
                            mainaddress
                        ]
                        let tempdefaultaddress = alladdress.find((address) => {
                            return address.IsDefault == true
                        }    )
                        // console.log(defaultaddress)
                        if(tempdefaultaddress){
                            setdefaultaddress(tempdefaultaddress)
                        }
                    }

                }
                else {
                    let alladdress = [
                        mainaddress
                    ]
                    if (mainaddress.AddressLine1 == '' && mainaddress.AddressLine2 == '' && mainaddress.AddressLine3 == '') {
                        let tempdefaultaddress = alladdress.find((address) => {
                            return address.IsDefault == true
                        }    )
                        // console.log(defaultaddress)
                        if(tempdefaultaddress){
                            setdefaultaddress(tempdefaultaddress)
                        }
                    }
                    else {
                        let tempdefaultaddress = alladdress.find((address) => {
                            return address.IsDefault == true
                        }    )
                        // console.log(defaultaddress)
                        if(tempdefaultaddress){
                            setdefaultaddress(tempdefaultaddress)
                        }

                    }

                }
            })

    }


    const getcartitems = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        if (cart !== null) {
            let qty = 0;
            cart.forEach((item) => {
                qty += item.quantity
            })
            setcartdataquantity(qty)
        }
        else {
            setcartdataquantity(0)
        }
    }
    const getcarttotal = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        if (cart !== null) {
            let total = 0;
            cart.forEach((item) => {
                console.log(item.data.SellingCost)
                total += item.data.SellingCost * item.quantity
            })
            setcarttotal(total)
        }
        else {
            setcarttotal(0)
        }
    }


    const getwishlist = async () => {
        let user = localStorage.getItem('token');
        user = JSON.parse(user);

        if (user) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/B2CCustomerWishList/GetByCustomer?OrganizationId=3&CustomerId=${user[0].B2CCustomerId}`);
                const data = await response.json();
                // console.log(data);
                if (data.Data && data.Data.length > 0) {
                    const products = await Promise.all(data.Data.map(async (item) => {
                        const productResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Product/Getbycode?OrganizationId=3&ProductCode=${item.ProductCode}`);
                        const productData = await productResponse.json();
                        return productData.Data;
                    }));
                    setwishlistdataquantity(products.length)
                }
                else {
                    setwishlistdataquantity(0)
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        checklogin()
        getcartitems()
        getcarttotal()
        getwishlist()
    }, [cartdataquantity, wishlistdataquantity, loggedIn])



    const [productpopup, setproductpopup] = useRecoilState(productPopupProvider)
    const [searchvalue, setsearchvalue] = useRecoilState(searchValueProvider)

    const [freeDelivery, setfreeDelivery] = useState(80)


    // console.log('carttotal', carttotal)
    return (
        <nav>
            <ToastContainer theme='dark' />
            {
                authPopupShow && <AuthPopup />
            }
            {
                cartPopupShow && <Cart />
            }
            {
                wishlistpopupshow && <Wishlist />
            }
            <div className='s1'>
                <img src={logo} alt='logo' className='logo' 
                onClick={()=>{
                    window.location.href='/'
                }}
                />

                <div className='searchbar'>
                    <input typ="text" placeholder="Search for products and categories" className='search'
                        onChange={(e) => {
                            setsearchvalue(e.target.value)
                        }}
                    />

                    <Link to={`/search/${searchvalue}`}>
                        <button
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>

                        </button>
                    </Link>
                </div>

                <div className='right'>

                    <div className='freedeliveryout'>
                        {
                            carttotal >= freeDelivery &&
                            <p>
                                Proceed to checkout for free delivery
                            </p>
                        }
                        {
                            carttotal > 0 && carttotal < freeDelivery &&
                            <p>
                                Add <span className='price'>$ {(freeDelivery - carttotal).toFixed(2)}</span> more for free delivery
                            </p>
                        }
                        {
                            carttotal == 0 && carttotal < freeDelivery &&
                            <p>
                                Add <span className='price'>$ {(freeDelivery - carttotal).toFixed(2)}</span> for free delivery
                            </p>
                        }
                        <div className='freedeliveryprogress'>
                            <div className='freedelivery'
                                style={{ width: `${(carttotal / freeDelivery) * 100}%` }}
                            >

                            </div>
                        </div>
                    </div>

                    <div className='cartout'
                        onClick={() => {
                            setwishlistpopupshow(true)
                            setAuthPopupShow(false)
                            setCartPopupShow(false)
                        }}
                    >


                        <div className='cart'>
                            <span className='qty'>{wishlistdataquantity}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                            </svg>
                        </div>
                        <p>Wish</p>
                    </div>
                    <div className='cartout'
                        onClick={() => {
                            setCartPopupShow(true)
                            setAuthPopupShow(false)
                            setwishlistpopupshow(false)
                        }}
                    >
                        <div className='cart'>
                            <span className='qty'>{cartdataquantity}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                        </div>
                        <p>Cart</p>
                    </div>


                    {
                        loggedIn ?
                            <Link to='/user/accountsettings' className={'stylenone'}>
                                <div className='userout'
                                // onClick={() => setAuthPopupShow(true)}
                                >

                                    <div className='user'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>
                                    </div>
                                    <p>Account</p>
                                </div>
                            </Link>
                            :
                            <div className='userout'
                                onClick={() => {
                                    setAuthPopupShow(true)
                                    setCartPopupShow(false)
                                    setwishlistpopupshow(false)
                                }}

                            >
                                <div className='user'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                    </svg>
                                </div>
                                <p>Sign In</p>
                            </div>
                    }
                </div>
            </div>


            {
                // user &&
                <div className='s2'>
                    <div className='s21'>
                        {
                            dropdownitems.map((item, index) => {
                                return (
                                    <DropdownComponent data={item} key={index} />
                                )
                            })
                        }
                    </div>
                    {user && 
                    <Link to='/user/address'
                        className={'stylenone'}
                    >
                        <div className='s22'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                            </svg>
                           <h3>Delivery:</h3>

                            {
                                !defaultaddress ?
                                    <p>Update your address</p>
                                    :
                                    <p>{defaultaddress?.AddressLine1 && (defaultaddress.AddressLine1)} {defaultaddress?.AddressLine2.length > 0 && (', ' + defaultaddress.AddressLine2)} {defaultaddress?.AddressLine3.length > 0 && (', ' + defaultaddress.AddressLine3)}</p>

                            }

                        </div>
                    </Link>
                    }
                </div>
            }
        </nav>
    )
}

export default Navbar