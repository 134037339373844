import React from 'react'
import { Link } from 'react-router-dom'
import './DropdownComponent.css'

const DropdownComponent = ({ data }) => {
  // console.log(data)
  const [show, setShow] = React.useState(false)
  return (
    <div className='dropdowncomponent'
      onClick={() => setShow(!show)}
      onMouseLeave={() => {
        if (data.items && data.items.length >= 0) {

        }
        else {
          setShow(false)
        }
      }}
    >
      <div className='s1'


      >
        {
          data.items && data.items.length > 0 ?

            <div
              className='droptitle'
            >
              <h3>{data.title}</h3>
              {
                show ?
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>

                  :
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
              }

            </div>

            :
            <Link to={data.link}
              className='stylenone'

            >
              <h3 >{data.title}</h3>
            </Link>
        }
        {show && <div className='border'></div>}
      </div>



      {show && data.items && data.items.length > 0 && <div className='s2' onMouseLeave={() => setShow(false)} 
      onClick={() => setShow(!show)}
      >

        {
          data.items && data.items.length > 0 && data.items.map((item, index) => {
            return (
              <div key={index} className='category'>  
                   <Link to={`/home/${item.category.Code}/${item.category.Name}/all`}
                      className='stylenone'
                    >
                      <h3>{item.category.Name}</h3>
                    </Link>
              </div>
            )
          })
        }
      </div>
      }
    </div>
  )
}

export default DropdownComponent