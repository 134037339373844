import React from 'react'
import { toast } from 'react-toastify'
import './ChangePassword.css'

const ChangePassword = ({ userid, oldpass, emailid }) => {

  console.log(userid, oldpass)

  const [oldpassword, setoldpassword] = React.useState('')
  const [newpassword, setnewpassword] = React.useState('')
  const [confirmpassword, setconfirmpassword] = React.useState('')

  const hanglechange = (e) => {
    e.preventDefault()

    if (oldpassword != oldpass) {
      toast.error('Old Password is Incorrect')
      return
    }


    if (newpassword != confirmpassword) {
      toast.error('New Password and Confirm Password does not match')
      return
    }

    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/EditProfilePassword',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            "OrgId": 3,
            "B2CCustomerId": userid,
            "EmailId": emailid,
            "Password": newpassword,
          }
        ),
      }
    )
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.Code === 200) {
          toast.success('Password Changed Successfully')
        }
        else {
          toast.error('Something Went Wrong')
        }
      })

  }

  return (
    <div className='accountsettings'>
      <h1 className='mainhead2'>Change Password</h1>
      <div className='form'

      >
        <div className='form-group'>
          <label htmlFor='email'>Old Password <span>*</span></label>
          <input type="password"
            onChange={(e) => setoldpassword(e.target.value)}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='email'>New Password <span>*</span></label>
          <input type="password"
            onChange={(e) => setnewpassword(e.target.value)}
          />

        </div>

        <div className='form-group'>
          <label htmlFor='email'>Confirm New Password <span>*</span></label>
          <input type="password"
            onChange={(e) => setconfirmpassword(e.target.value)}
          />
        </div>
      </div>

      <button className='mainbutton1'

        onClick={(e) => hanglechange(e)}
      >Save Changes</button>
    </div>
  )
}

export default ChangePassword