import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { cartQuantity } from '../../Providers/CartQuantity'
import { cartReloadState } from '../../Providers/CartReload'
import './CartItem.css'
import { Link } from 'react-router-dom'
import { cartPopupState } from '../../Providers/CartPopupProvider'
import noimage from '../../ASSETS/noimage.png'
const CartItem = ({ itemdata, getcartdata }) => {
    console.log(itemdata.data)


    const [showdelete, setshowdelete] = useState(false)
    const [cartreload, setcartreload] = useRecoilState(cartReloadState)
    const [cartdataquantity, setcartdataquantity] = useRecoilState(cartQuantity)

    const getcartitems = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        if (cart !== null) {
            let qty = 0;
            cart.forEach((item) => {
                qty += item.quantity
            })
            setcartdataquantity(qty)
        }
        else {
            setcartdataquantity(0)
        }
    }


    const deleteitem = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        let newcart = cart.filter((item) => item.data.ProductCode !== itemdata.data.ProductCode)
        localStorage.setItem('cart', JSON.stringify(newcart))
        // setcartreload(!cartreload)
        getcartdata()
        getcartitems()
    }


    const increaseqty = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        cart.forEach((item) => {
            if (item.data.ProductCode === itemdata.data.ProductCode) {
                item.quantity = item.quantity + 1
            }
        })
        localStorage.setItem('cart', JSON.stringify(cart))
        getcartdata()
        getcartitems()
    }

    const decreaseqty = () => {
        let cart = JSON.parse(localStorage.getItem('cart'));
        const updatedCart = [];
      
        cart.forEach((item) => {
          if (item.data.ProductCode === itemdata.data.ProductCode && item.quantity > 1) {
            item.quantity = item.quantity - 1;
          } else if (item.data.ProductCode !== itemdata.data.ProductCode || item.quantity > 1) {
            // Keep items that are not the one being decreased or have a quantity greater than 1
            updatedCart.push(item);
          }
        });
      
        // Update the cart in local storage
        localStorage.setItem('cart', JSON.stringify(updatedCart));
      
        // Refresh cart data and items
        getcartdata();
        getcartitems();
      };

    // const deleteitem = () => {
    //     let cart = JSON.parse(localStorage.getItem('cart'))
    //     let newcart = cart.filter((item) => item.data.ProductCode !== itemdata.data.ProductCode)
    //     localStorage.setItem('cart', JSON.stringify(newcart))
    //     // setcartreload(!cartreload)
    //     getcartdata()
    //     getcartitems()
    // }


    // const increaseqty = () => {
    //     let cart = JSON.parse(localStorage.getItem('cart'))
    //     cart.forEach((item) => {
    //         if (item.data.ProductCode === itemdata.data.ProductCode) {
    //             item.quantity = item.quantity + 1
    //         }
    //     })
    //     localStorage.setItem('cart', JSON.stringify(cart))
    //     getcartdata()
    //     getcartitems()
    // }
    

    // const decreaseqty = () => {
    //     let cart = JSON.parse(localStorage.getItem('cart'))
    //     cart.forEach((item) => {
    //         if (item.data.ProductCode === itemdata.data.ProductCode && item.quantity > 1) {
    //             item.quantity = item.quantity - 1
    //         }
    //     })
    //     localStorage.setItem('cart', JSON.stringify(cart))
    //     getcartdata()
    //     getcartitems()
    // }

    const [cartPopupShow, setCartPopupShow] = useRecoilState(cartPopupState);
    return (
        <div className='cartitem'>
            <div className='s1'
                onMouseEnter={() => setshowdelete(true)}
                onMouseLeave={() => setshowdelete(false)}
            >
                <img src={itemdata.data.ProductImageFileName != 'NoImage.jpg' && itemdata.data.ProductImageFileName !== "" ? itemdata.data.ProductImagePath: noimage} alt='no img' />
                {
                    showdelete && <div className='removeitem'>
                        <button
                            onClick={deleteitem}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                }
            </div>
            <div className='s2'>
                <Link to={`/product/${itemdata.data.ProductCode}`} className='stylenone'
                 onClick={() => {
                    setCartPopupShow(false);
                 }}
                >
                    <p>{itemdata.data.ProductName}</p>
                </Link>
                <div className='qty'>
                    <button className='qtybtn'
                        onClick={decreaseqty}
                    >-</button>
                    <p>{itemdata.quantity}</p>
                    <button className='qtybtn'
                        onClick={increaseqty}
                    >+</button>
                </div>
            </div>
            <div className='s3'
            >
                <Link to={`/product/${itemdata.data.ProductCode}`} className='stylenone'    onClick={() => {
                    setCartPopupShow(false);
                 }}>
                    <p>$ {(itemdata?.data?.SellingCost * itemdata.quantity).toFixed(2)}</p>
                </Link>
            </div>
        </div>
    )
}

export default CartItem