import React from 'react'
import './AuthPopup.css'
import Switch from "react-switch";
import logo from '../../ASSETS/logo.png'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { authPopupState } from '../../Providers/AuthPopupProvider';
import { loginState } from '../../Providers/LoginProvider';

const AuthPopup = () => {
    const [authPopupShow, setAuthPopupShow] = useRecoilState(authPopupState);
    const [checked, setChecked] = React.useState(false);
    const [loggedIn, setLoggedIn] = useRecoilState(loginState);
    const handleChange = (nextChecked) => {
        setChecked(nextChecked);
    }


    const [logindata, setlogindata] = React.useState({})

    const handleLogin = async () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/CustomerLogin',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "OrgId": 3,
                    "UserName": logindata.Username,
                    "Password": logindata.Password,
                    "BranchCode": "HQ"
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('login page  ',data)
                if (data.Code == 200 && data.Message == "Sucess") {

                    localStorage.setItem('token', JSON.stringify(data.Data))
                    // save password in local storage
                    localStorage.setItem('password', JSON.stringify(logindata.Password))
                    updateuserdata()
                    setLoggedIn(true)
                    toast.success('Login Successfull',
                        {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            //color
                        }
                    )

                    setTimeout(() => {
                        setAuthPopupShow(false)
                        window.location.reload()
                    }, 1000);
                }
                else {
                    toast.error('Login Failed')
                }
            })
    }

    const updateuserdata = () => {
        let user = localStorage.getItem('token')
        user = JSON.parse(user)
        // console.log('user customer id',user[0])
        fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/GetbycodeOrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION+'&B2CCustomerId=' + user[0].B2CCustomerId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            if (data.Status === true && data.Code === 200) {
              localStorage.setItem('token', JSON.stringify(data.Data))
              // getaddress()
            }
            else {
              toast.error('Error Fetching User Data')
            }
          })
      }
    

    const [showlogin, setshowlogin] = React.useState('0')



    // signup
    const [signupdata, setsignupdata] = React.useState({
        "OrgId": 3,
        "BranchCode": "HQ",
        "B2CCustomerId": "",
        B2CCustomerName: "",
        EmailId: "",
        Password: "",
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        MobileNo: "",
        CountryId: "IND",
        PostalCode: "",
        IsActive: true,
        IsApproved: true,
        CreatedBy: "user",
        CreatedOn: new Date(),
        ChangedBy: "user",
        ChangedOn: new Date(),
        Orders: [],
        Address: [
            {}
        ]
    })

    const getdatafrompostalcode = async () => {
        console.log(signupdata.PostalCode)


        let url = `https://developers.onemap.sg/commonapi/search?searchVal=${signupdata.PostalCode}&returnGeom=N&getAddrDetails=Y&pageNum=1`
        const response = await fetch(url);
        const data = await response.json();
        // console.log(data.results[0])

        setsignupdata({
            ...signupdata,
            // AddressLine1: data.results[0].BLK_NO,
            // AddressLine2: data.results[0].BUILDING,
            AddressLine3: data.results[0].ADDRESS,
        })
    }

    const handleSignup = async () => {
        // let address1 = {
        //     "OrgId": 3,
        //     "DeliveryId": 0,
        //     "CustomerId": new Date().getTime(),
        //     "Name": signupdata.B2CCustomerName,
        //     "AddressLine1": signupdata.AddressLine1,
        //     "AddressLine2": signupdata.AddressLine2,
        //     "AddressLine3": signupdata.AddressLine3,
        //     "CountryId": signupdata.CountryId,
        //     "PostalCode": signupdata.PostalCode,
        //     "Mobile": signupdata.MobileNo,
        //     "Phone": signupdata.MobileNo,
        //     "Fax": "",
        //     "IsDefault": true,
        //     "IsActive": true,
        //     "CreatedBy": "",
        //     "CreatedOn": new Date(),
        //     "ChangedBy": "",
        //     "ChangedOn": new Date()
        // }

        let tempdata = {
            ...signupdata,
            // Address: [address1]
        }

        // console.log(tempdata)
        fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/GetbyEmail?OrganizationId=3&EmailId=' + signupdata.EmailId)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data.Data[0])
                if (data.Data[0].EmailId !== null) {
                    // console.log("email already exists")
                    toast.error("Email already exists")
                }
                else {
                    // console.log("email does not exists")


                    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/Create',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(tempdata),
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            console.log(data)
                            if (data.Code == 200) {
                                toast.success('Signup Successfull',
                                    {
                                        position: "top-center",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        //color
                                    }
                                )

                                setshowlogin('0')
                            }
                            else {
                                toast.error('Signup Failed')
                            }
                        })
                }
            })
            .catch((error) => {
                return false
            })


    }

    return (
        <div
            className='auth-popup'
        >
            <div className='auth-popup__content'>
                <button className='auth-popup__close-btn'
                    onClick={() => {
                        // if(loggedIn){
                            setAuthPopupShow(false)
                        // }
                        // else{
                        //     toast.error('Please Login First')
                        //     setAuthPopupShow(true)
                        // }
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                {
                    showlogin == '0' &&
                    <div className='login'>
                        <img src='https://borobazar.vercel.app/_next/image?url=%2Fassets%2Fimages%2Flogin.png&w=1920&q=75'
                            alt='login' className='login__img' />
                        <form>
                            <div className='s1'>
                                <img src={logo} alt='logo' className='logo' />
                                <h1>Welcome Back!</h1>
                                <p>Don't have an account?
                                    <a
                                        onClick={() => {
                                            setshowlogin('1')
                                        }}
                                    >Create Account</a>
                                </p>
                            </div>
                            <div className='formcont'>
                                <label htmlFor='email'>Email Address</label>
                                <input type='email' name='email' id='email'
                                    onChange={(e) => {
                                        setlogindata({ ...logindata, Username: e.target.value })
                                    }}

                                />
                            </div>
                            <div className='formcont'>
                                <label htmlFor='password'>Password</label>
                                <input type='password' name='password' id='password'
                                    onChange={(e) => {
                                        setlogindata({ ...logindata, Password: e.target.value })
                                    }}
                                />
                            </div>
                            <div className='formcont1'>
                                {/* <div className='row'>
                                    <span htmlFor='remember'>Remember Me</span>
                                    <Switch onChange={handleChange} checked={checked}
                                        onColor='#02b290'
                                        onHandleColor='#fff'
                                        // handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                        className='react-switch'
                                        width={60}
                                    />

                                </div> */}
                                <a href='#'>Forgot Password ?</a>
                            </div>

                            <button className='btn'
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleLogin()
                                }}
                            >Sign In</button>
                        </form>
                    </div>
                }
                {
                    // signup
                    showlogin == '1' &&
                    <div className='login'>
                        <div className='login__img'>
                            <img src='https://borobazar.vercel.app/_next/image?url=%2Fassets%2Fimages%2Flogin.png&w=1920&q=75'
                                alt='login' />
                        </div>
                        <form>
                            <div className='s1'>
                                <img src={logo} alt='logo' className='logo' />
                                <h1>SignUp for free!</h1>
                                <p>Already Registered?
                                    <a
                                        onClick={() => {
                                            setshowlogin('0')
                                        }}
                                    >Sign In Now</a>
                                </p>
                            </div>

                            <div className='formcont'>
                                <label htmlFor='name'>Name</label>
                                <input type='text' name='name' id='name'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, B2CCustomerName: e.target.value })
                                    }}
                                />
                            </div>

                            <div className='formcont'>
                                <label htmlFor='name'>Phone</label>
                                <input type='text' name='phone' id='phone'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, MobileNo: e.target.value })
                                    }}
                                />
                            </div>
                            <div className='formcont'>
                                <label htmlFor='email'>Email Address</label>
                                <input type='email' name='email' id='email'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, EmailId: e.target.value })
                                    }}

                                />
                            </div>
                            <div className='formcont'>
                                <label htmlFor='password'>Password</label>
                                <input type='password' name='password' id='password'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, Password: e.target.value })
                                    }}
                                />
                            </div>

                            {/* pincode , address line 1 , address line 2 , address line 3 */}
                            <div className='formcont'>
                                <label htmlFor='pincode'>Pincode</label>
                                <input type='text' name='pincode' id='pincode'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, Pincode: e.target.value })
                                    }}
                                />
                            </div>

                            <div className='formcont'>
                                <label htmlFor='address1'>Address Line 1</label>
                                <input type='text' name='address1' id='address1'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, AddressLine1: e.target.value })
                                    }}
                                />
                            </div>

                            <div className='formcont'>
                                <label htmlFor='address2'>Address Line 2</label>
                                <input type='text' name='address2' id='address2'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, AddressLine2: e.target.value })
                                    }}
                                />

                            </div>

                            <div className='formcont'>
                                <label htmlFor='address3'>Address Line 3</label>
                                <input type='text' name='address3' id='address3'
                                    onChange={(e) => {
                                        setsignupdata({ ...signupdata, AddressLine3: e.target.value })
                                    }}
                                />

                            </div>

                            {/* <div className='formcont1'>
                                    <div className='row'>
                                        <span htmlFor='remember'>Remember Me</span>
                                        <Switch onChange={handleChange} checked={checked}
                                            onColor='#02b290'
                                            onHandleColor='#fff'
                                            // handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                            className='react-switch'
                                            width={60}
                                        />

                                    </div>
                                    <a href='#'>Forgot Password ?</a>
                                </div> */}

                            <button className='btn'
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleSignup()
                                }}
                            >Sign up</button>
                        </form>
                    </div>
                }
            </div>
            <ToastContainer theme="dark" />
        </div>
    )
}

export default AuthPopup