import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { cartQuantity } from '../../Providers/CartQuantity'
import { wishQuantity } from '../../Providers/WishListQuantityProvider'
import { cartReloadState } from '../../Providers/CartReload'
import './ProductCard.css'
import { productPopupProvider } from '../../Providers/ProductpopupProvider'
import ProductPopup from './ProductPopup'
import noimage from '../../ASSETS/noimage.png'
import { productPopupIdProvider } from '../../Providers/ProductPopupIdProvider'

const ProductCard = ({ data, wishlist }) => {
  const [show, setshow] = useState(false)
  const [count, setCount] = useState(1)
  // const noimage = 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'
  // const getproductid = () => {
  //   alert(data.id)
  // }

  const [cartdataquantity, setcartdataquantity] = useRecoilState(cartQuantity)
  const [wishlistdataquantity, setwishlistdataquantity] = useRecoilState(wishQuantity)

  const [productpopup, setproductpopup] = useRecoilState(productPopupProvider)
  const [productpopupid, setproductpopupid] = useRecoilState(productPopupIdProvider)
  const navigate = useNavigate()
  
  useEffect(() => {
    // Code to run when the component mounts
    let cart = JSON.parse(localStorage.getItem('cart'));
    console.log(cart);
    if (cart) {
      cart.forEach((item) => {
        if (item.data.ProductCode === data.ProductCode) {
          setshow(true);
          console.log(item.quantity);
          setCount(item.quantity);
          getwhishlist();
        }
      });
    }
  }, []);

  const getcartitems = () => {
    let cart = JSON.parse(localStorage.getItem('cart'))
    if (cart !== null) {
      let qty = 0;
      cart.forEach((item) => {
        qty += item.quantity
      })
      setcartdataquantity(qty)
    }
    else {
      setcartdataquantity(0)
    }
  }



  const addtocart = () => {
    let cart = JSON.parse(localStorage.getItem('cart'))
    if (cart === null) {
      cart = []

      cart.push({ data, quantity: count })
      localStorage.setItem('cart', JSON.stringify(cart))
      toast.success('Product added to cart', {
        position: "top-right",
        autoClose: 1000,
      })
      getcartitems()
    }
    else {
      let flag = 0
      cart.forEach((item) => {
        if (item.data.ProductCode === data.ProductCode) {
          flag = 1
          item.quantity = item.quantity + count
          setshow(true);
        }
      })
      if (flag === 0) {
        cart.push({ data, quantity: count })
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      getcartitems()
    }
  }

  const incrementcartqty = () => {
    let cart = JSON.parse(localStorage.getItem('cart'))
    cart.forEach((item) => {
      if (item.data.ProductCode === data.ProductCode) {
        item.quantity = item.quantity + 1
      }
    })
    localStorage.setItem('cart', JSON.stringify(cart))
    getcartitems()

  }

  const decrementcartqty = () => {
    let cart = JSON.parse(localStorage.getItem('cart'))
    cart.forEach((item) => {
      if (item.data.ProductCode === data.ProductCode && item.quantity > 1) {
        item.quantity = item.quantity - 1
      }
    })
    localStorage.setItem('cart', JSON.stringify(cart))
    getcartitems()

  }


  // console.log(data.ProductImage[0].image)
  const [prodid, setprodid] = useState(null)
  const [isinwhishlist, setisinwhishlist] = useState(wishlist)
  const addtowhishlist = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    // console.log(user)
    // console.log({
    //   "OrgId": "2",
    //   "CustomerId": user[0].B2CCustomerId,
    //   "ProductCode": data.ProductCode,
    //   "ProductName": data.ProductName,
    //   "IsActive": true,
    //   "CreatedBy": user[0].B2CCustomerId,
    //   "CreatedOn": `${new Date()}`,
    // })

    if (user) {
      fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerWishList/Create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "OrgId": "2",
          "CustomerId": user[0].B2CCustomerId,
          "ProductCode": data.ProductCode,
          "ProductName": data.ProductName,
          "IsActive": true,
          "CreatedBy": user[0].B2CCustomerId,
          "CreatedOn": `${new Date()}`,
        }),
      })
        .then(res => res.json())
        .then(data => {
          console.log(data)
          if (data.Code == 200) {
            setisinwhishlist(true)
            getwhishlist()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    else {
      toast.error('Please login to add to wishlist', {
        position: "top-right",
        autoClose: 1000,
      })
    }
  }

  const getwhishlist = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    if (user) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/B2CCustomerWishList/GetByCustomer?OrganizationId=3&CustomerId=${user[0].B2CCustomerId}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          if (data.Code == 200) {
            data.Data.forEach((item) => {
              setwishlistdataquantity(data.Data.length)
              if (item.ProductCode === data.ProductCode) {
                setisinwhishlist(true)
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  // console.log(wishlist)

  // console.log(data.EcommerceDetail[0].StockAvailability, data.EcommerceDetail[0].QtyOnHand)

  return (
    <div className='product'>
      {
        isinwhishlist ?
          <div className='wishlistbtn1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>
          </div>
          :
          <div className='wishlistbtn'
            onClick={() => {
              addtowhishlist()
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>
          </div>
      }
     
      <ToastContainer />
      <div className='s1'
        onClick={() => {
          setproductpopupid(data.ProductCode)
          // setproductpopup(true)
          navigate(`/product/${data.ProductCode}`)
          // alert(data.ProductCode)
          window.location.href = `/product/${data.ProductCode}`
          
        }}
      >
        {
          data.ProductImagePath ?
            <img src={data.ProductImagePath} alt={'no img'} />
            :
            <img src={noimage} alt={'no img'} />
        }
      </div>
      <div className='s2'>
        <h3>
          $ {
            data.SellingCost.toFixed(2)
          }
        </h3>
        <p>{
          data.ProductName
        }</p>
      </div>

      {
        show ?
          <div className='addbtn'>
            <div className='qty'>
              <button className='qtybtn'
                onClick={() => {
                  if (count > 1) {
                    setCount(count - 1)
                    decrementcartqty()
                  }
                }}
              >-</button>
              <p>{count}</p>
              <button className='qtybtn'
                onClick={() => {
                  if (data?.EcommerceDetail && data.EcommerceDetail[0].StockAvailability) {
                    if (count < data.EcommerceDetail[0].QtyOnHand) {
                      setCount(count + 1)
                      incrementcartqty()
                    }
                    else {
                      toast.error('You have reached maximum quantity', {
                        position: "top-right",
                        autoClose: 1000,
                      })
                    }
                  }
                  else {
                    setCount(count + 1)
                    incrementcartqty()
                  }
                }}
              >+</button>
              <button className='closebtn'
                onClick={() => setshow(false)}
              >x</button>
            </div>
          </div>
          :
          <div className='addbtn'>
            {/* <Link
              to={`/product/${data.ProductCode}`}
            > */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"

              onClick={() => {
                setproductpopupid(data.ProductCode)
                // setproductpopup(true)
                navigate(`/product/${data.ProductCode}`)
              }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            {/* </Link> */}


            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"
              onClick={() => {
                addtocart()
                setshow(true)
              }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>

          </div>
      }
      {/*   
      
      <div className='s3'>
        <p>{data.ProductUnit}</p>
      </div>
       */}
    </div>
  )
}

export default ProductCard