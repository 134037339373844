import React from 'react'
import noimage from '../../ASSETS/noimage.png'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { wishPopupState } from '../../Providers/WishPopupProvider'

const WishListItem = ({ item, getwishlist }) => {
    console.log(item)
    const [showdelete, setshowdelete] = React.useState(false)

    const removewishlist = () => {
        let user = JSON.parse(localStorage.getItem('token'));

        // console.log(`${process.env.REACT_APP_BACKEND_URL}/B2CCustomerWishList/Remove?OrganizationId=3&CustomerId=${user[0].B2CCustomerId}&ProductCode=${item[0].ProductCode}&UserName=${user[0].B2CCustomerName}`);

        fetch(`${process.env.REACT_APP_BACKEND_URL}/B2CCustomerWishList/Remove?OrganizationId=3&CustomerId=${user[0].B2CCustomerId}&ProductCode=${item[0].ProductCode}&UserName=${user[0].B2CCustomerName}`)
            .then(res => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then(data => {
                getwishlist();
            })
            .catch(err => {
                getwishlist();
                console.log(err);
            });
    };

    const addtocart = () => {
        let cart = JSON.parse(localStorage.getItem('cart'))
        if (cart === null) {
            cart = []

            cart.push({ data: item[0], quantity: 1 })
            localStorage.setItem('cart', JSON.stringify(cart))
            toast.success('Product added to cart', {
                position: "top-right",
                autoClose: 1000,
            })
            removewishlist()

        }
        else{
            let flag = 0
            cart.forEach((item1) => {
                if (item1.data.ProductCode === item[0].ProductCode) {
                    flag = 1
                    item1.quantity = item1.quantity + 1
                }
            })
            if (flag === 0) {
                cart.push({ data: item[0], quantity: 1 })
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            toast.success('Product added to cart', {
                position: "top-right",
                autoClose: 1000,
            })
            removewishlist()
        }
        // else {
        //     let flag = 0
        //     cart.forEach((item1) => {
        //         if (item1.data.ProductCode === item.ProductCode) {
        //             flag = 1
        //             item1.quantity = item1.quantity + 1
        //         }
        //     })
        //     if (flag === 0) {
        //         cart.push({ data: item, quantity: 1 })
        //     }
        //     localStorage.setItem('cart', JSON.stringify(cart))
        //     toast.success('Product added to cart', {
        //         position: "top-right",
        //         autoClose: 1000,
        //     })
        //     removewishlist()
        // }


        let user = JSON.parse(localStorage.getItem('token'));

    }

    const [wishlistpopupshow, setwishlistpopupshow] = useRecoilState(wishPopupState);
    return (
        <div
            className='wishlistitem'
        >
            <div className='s1'
                onMouseEnter={() => setshowdelete(true)}
                onMouseLeave={() => setshowdelete(false)}
            >
                <img src={item[0].ProductImageFileName != 'NoImage.jpg' ? item[0].ProductImagePath : noimage} alt='no image'
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noimage
                    }}

                />
                {
                    showdelete &&
                    <div className='removeitem'>
                        <button
                            onClick={removewishlist}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                }
            </div>
            <div className='s2'>
                <Link to={`/product/${item[0].ProductCode}`} className='stylenone'
                    onClick={() => {
                        setwishlistpopupshow(false)
                    }}
                >
                    <p>{item[0].ProductName}</p>
                </Link>
            </div>
            <div className='s3'>
                <p>$ {item[0].SellingCost?.toFixed(2)}</p>
                <div className='cartout'
                    onClick={addtocart}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default WishListItem