import React from 'react'
import noimage from '../../ASSETS/noimage.png'
import './CategoryTopbar.css'
import { Link, useNavigate } from 'react-router-dom'

const CategoryTopbar = ({ categories }) => {

    categories = [
        {
            category: {
                "Code": "all",
                "Name": "All Products",
                "CategoryImageFilePath": noimage,
                "subcategories": []
            }
        },
        ...categories
    ]

    const navigate = useNavigate()

    return (
        <select className='categorytopbar'
        //  onChange={(e) => {
        //     window.location.href = `/searchbycategory/${e.target.value.Code}/${e.target.value.Name}/all`
        //     // navigate(`/searchbycategory/${e.target.value.Code}/${e.target.value.Name}/all`)
        //  }}
        onChange={(e) => {
            console.log(JSON.parse(e.target.value))
            navigate(`/searchbycategory/${JSON.parse(e.target.value).Code}/${JSON.parse(e.target.value).Name}/all`)
        }}
        >
            <option value='' disabled>Select Category</option>
            {
                categories && categories.length > 0 && categories.map((item) => {
                    return (
                        <option value={JSON.stringify(item.category)}

                            onClick={(e) => {
                                console.log(e.target.value)
                                // window.location.href = `/searchbycategory/${e.target.value.Code}/${e.target.value.Name}/all`
                                // navigate(`/searchbycategory/${e.target.value.Code}/${e.target.value.Name}/all`)
                            }}
                        >

                            {item.category.Name}

                        </option>
                    )
                })
            }
        </select>
    )
}

export default CategoryTopbar